import React, { Fragment } from "react";
import "../sass/main.scss";

const PageNotFound = () => {
  return (
    <Fragment>
      <header>Page Not Found</header>
    </Fragment>
  );
};

export default PageNotFound;